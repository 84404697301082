<template>
  <div class="chat-fundamentals">
    <div class="cilinder">
      <div class="step step1">
        <strong class="badge" v-if="value <= 2" style="color: white">
          Não recomendado
        </strong>
      </div>
      <div class="step step2">
        <strong
          class="badge"
          v-if="value > 2 && value <= 4"
          style="color: white"
        >
          Risco alto
        </strong>
      </div>
      <div class="step step3">
        <strong class="badge text-black" v-if="value > 4 && value <= 6">
          Risco médio
        </strong>
      </div>
      <div class="step step4">
        <strong class="badge text-black" v-if="value > 6 && value <= 8">
          Risco baixo
        </strong>
      </div>
      <div class="step step5">
        <strong class="badge" v-if="value > 8 && value <= 10">
          Risco irrelevante
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    value() {
      return Number(this.total);
    },
  },
};
</script>

<style lang="scss">
.chat-fundamentals {
  position: relative;
  height: 26px;
  border-radius: 50px;
  max-width: 170px;
  margin: 1em auto;
  margin-top: -30px;

  .cilinder {
    position: absolute;
    top: 0;
    display: flex;
    border-radius: 50%;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    .step {
      flex: 1;
      display: flex;
      align-items: center;
      &.step1 {
        background: #ff3300;
        .badge {
          color: white !important;
        }
      }
      &.step2 {
        background: #f84438;
      }
      &.step3 {
        background: #e9c31b;
      }
      &.step4 {
        background: #b7ec82;
      }
      &.step5 {
        background: #589c14;
        .badge {
          color: white !important;
        }
      }
    }
  }
}
</style>
