const chartOptions = {
  chart: {
    height: 650,
    width: "100%",
    type: "radialBar",
    offsetY: 0,
    toolbar: {
      show: false,
    },
    grid: {
      margin: {
        left: "auto",
        right: "auto",
      },
    },
  },
  colors: ["#8be15e"],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      offsetY: 40,
      hollow: {
        margin: 0,
        size: "80%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: false,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.04,
        },
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: 0,
          show: true,
          color: "#888",
          fontSize: "17px",
        },
        value: {
          formatter: (val) => parseInt(val * 10),
          color: "#5d5d5d",
          offsetY: -40,
          fontSize: "66px",
          show: true,
        },
      },
    },
  },
  legend: {
    show: true,
    position: "top",
    horizontalAlign: "center",
    floating: true,
    fontSize: "14px",
    fontWeight: 400,
    formatter: () => "Score de crédito",

    labels: {
      colors: undefined,
      useSeriesColors: true,
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "horizontal",
      shadeIntensity: 0,
      gradientToColors: ["#e55c43"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 90, 100],
    },
  },

  labels: ["de 1000"],
};

export { chartOptions };
